import * as React from "react";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate } from "react-router-dom";
import { clearSteps } from "../hooks/useApplicationSteps";
import { Box } from "@mui/material";
import { useProfile } from "../hooks/useProfile";
import Copyright from "../Shared/Copyright";



const theme = createTheme();


export default function Confirm() {
  const navigate = useNavigate();
  const { profile } = useProfile()

  const logout = () => {
    sessionStorage.clear();
    clearSteps();
    navigate("/newlogin");
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper
          sx={{
            my: { xs: 3, md: 6 },
            p: { xs: 2, md: 3 },
          }}
        >
          <React.Fragment>
            <Box sx={{ display: 'flex', justifyContent: 'center' }} >
              <Avatar alt="Avatar" src="/assets/img/welli-avatar.jpeg" sx={{ width: 150, height: 150, marginBottom: '15px' }} />
            </Box>
           
              <Typography variant="h6" align="left" gutterBottom>
               { profile?.nombres}
              </Typography>



            <Typography variant="body1" color="text.secondary" align="left" gutterBottom sx={{ marginBottom: '40px' }}>
              ¡Agradecemos que hayas tomado un <strong> crédito  con Welli! </strong>  Hemos notificado
              a tu médico para que puedas iniciar tu procedimiento.
              Recuerda que los fondos serán transferidos directamente a la cuenta del médico.
            </Typography>



            <Typography variant="h6" align="left" sx={{ marginTop: '40px', marginBottom: '10px' }} gutterBottom>
              ¿Cómo pagar las cuotas de tu crédito?
            </Typography>


            <Typography variant="body" align="center" color="text.secondary" gutterBottom >

              Recibirás el extracto en tu correo unos días antes de la fecha de vencimiento del pago.
              El pago oportuno de tu crédito es el día 30 de cada mes.
              Puedes realizar el pago desde tu cuenta bancaria utilizando PSE o en efectivo a través de Efecty o Puntored.
            </Typography>


            <Typography variant="body2" align="left" sx={{ marginTop: '40px', marginBottom: '10px' }} gutterBottom>
              Para más información, visita nuestro sitio web en   <Link sx={{ cursor: 'pointer', }} target="_blank" href="www.welli.com.co"> www.welli.com.co</Link>
            </Typography>

            <Link sx={{ cursor: 'pointer', }} target="_blank" href="wa.link/7peix3">
              <Typography variant="body2" align="left" sx={{ marginBottom: '10px' }} gutterBottom>
                <strong> ¿Tienes alguna pregunta?, </strong>Escríbenos por WhatsApp haciendo click acá

              </Typography>
            </Link>

            <Container component="main" maxWidth="xs">

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={logout}
              >
                Cerrar Sesión
              </Button>
            </Container>
          </React.Fragment>
        </Paper>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
