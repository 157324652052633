import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import GlobalStyles from "@mui/material/GlobalStyles";
import { useState, useEffect } from "react";
import { CircularProgress, FormControl } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import RejectModal from "./RejectModal";
import { selectApplications } from "../store/applications";
import { useSelector } from "react-redux/es/hooks/useSelector";

import GenericModal from "./GenericModal";
import { NumericFormatCustom } from "../Shared/NumericFormatCustom";
import { useAuth } from "../hooks/useAuth";
import { getPlanCoutes } from "../api/application";
import { currencyFormat } from "../helpers/formaHelper";
import { MIN_AMOUNT_VALUE, MIN_FEE_AMOUNT } from "../core/enums/credit";
import BasicModalProcess from "./InProcessModal";
import BodyRejectedComponent from "../Shared/BodyRejectedComponent";

export default function Offer(props) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const { formCompleted } = props;
  const [cuotas, setCuotas] = useState([]);
  const [cuota, setCuota] = useState(0);
  const [montoInicial, setMontoInicial] = useState(0);
  const [estado, setEstado] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showRejectedModal, setShowRejectedModal] = useState(false);
  const [showOnHoldModal, setShowOnHoldModal] = useState(false);
  const [showInProcess, setShowInProcess] = useState(false);
  const [idApplication, setIdApplication] = useState(false);
  const [fetchingOptions, setFetchingOptions] = useState("idle");
  useSelector(selectApplications);
  const { logOut } = useAuth();

  const TRIES_TO_APP = 6;
  const getOptions = async (appId) => {
    try {
      setFetchingOptions("pending");
      setCuotas([]);
      setCuota(0);
      const { data } = await getPlanCoutes(appId);
      orderCuotas(data.data);
      setFetchingOptions("succeded");
    } catch (error) {
      console.log(error);
      setFetchingOptions("rejected");
    }
  };

  useEffect(() => {
    const getApplications = (tries = 0) => {
      const profileId = sessionStorage.getItem("profile_id");
      axios
        .get(
          `${process.env.REACT_APP_WELLI_API}/api/applications/risk/${profileId}`
        )
        .then((resp) => {
          const estadoDb =
            resp?.data?.risk?.estado || resp?.data?.app?.estado || null;

          if (estadoDb === "approved") {
            if (!montoInicial) {
              setMontoInicial(resp.data.risk.monto);
            }
            setEstado(estadoDb);
            setIdApplication(resp.data.app.id);

            getOptions(resp.data.app.id);
          } else if (estadoDb === "rejected") {
            setShowRejectedModal(true);
          } else if (estadoDb === "on_hold_docs") {
            setShowOnHoldModal(true);
          } else if (estadoDb === "risk_in_process" && tries < TRIES_TO_APP) {
            setShowInProcess(true);
            setTimeout(() => {
              getApplications(tries + 1);
            }, 10000);
          } else if (estadoDb === "risk_in_process" && tries == TRIES_TO_APP) {
            setShowModal(true);
          } else {
            setShowModal(true);
          }
        })
        .catch(() => {
          setShowRejectedModal(true);
        });
    };

    getApplications();
  }, [setValue]);

  const updateNewPaymentPlan = (handleGetOptions = false) => {
    setFetchingOptions("pending");
    axios
      .patch(
        `${process.env.REACT_APP_WELLI_API}/api/application/${idApplication}`,
        {
          monto: parseInt(getValues().monto_inicial),
          cuotas: parseInt(cuota),
        }
      )
      .then(() => {
        if (handleGetOptions) {
          getOptions(idApplication);
        } else {
          formCompleted();
        }
      })
      .catch((error) => {
        console.log("error", error);
        setFetchingOptions("rejected");
      });
  };

  const orderCuotas = (dbCuotas) => {
    const dbCoutasEntries = Object.entries(dbCuotas).sort(
      (a, b) => b[0] - a[0]
    );
    const newCoutas = dbCoutasEntries
      .filter((x) => x[1].resulado == true)
      .map((c) => ({ key: c[0], value: c[1]["cuota_asignacion"] }))
      .filter((x) => x.value >= MIN_FEE_AMOUNT);
    if (newCoutas.length) {
      setCuotas(newCoutas);
      setCuota(newCoutas[0].key);
    }
  };

  const notValidatedApplication = () => {
    logOut();
  };

  return (
    <>
      <React.Fragment>
        <RejectModal
          show={showModal}
          logout={notValidatedApplication}
        ></RejectModal>
        <BasicModalProcess show={showInProcess}></BasicModalProcess>

        <GenericModal
          show={showOnHoldModal}
          title="Felicitaciones! Tu crédito ha sido pre-aprobado. "
          bodyComponent={
            <>
              <Typography
                variant="body1"
                color="text.secondary"
                align="left"
                gutterBottom
                sx={{ marginBottom: "20px" }}
              >
                Estamos a un paso de poder finalizar el proceso y que puedas
                tomar tu crédito. Sin embargo, antes de eso, necesitamos que nos
                envíes algunos documentos adicionales.
              </Typography>

              <Typography
                variant="body1"
                color="text.secondary"
                align="left"
                gutterBottom
                sx={{ marginBottom: "20px" }}
              >
                Si eres <strong>empleado</strong> , por favor envíanos tus
                últimos 3 desprendibles de nómina y la carta con certificación
                laboral a la fecha. <br />
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                align="left"
                gutterBottom
                sx={{ marginBottom: "20px" }}
              >
                Si eres <strong>independiente</strong>, por favor compártenos
                tus últimos 3 extractos bancarios y tu declaración de renta.{" "}
                <br />
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                align="left"
                gutterBottom
                sx={{ marginBottom: "20px" }}
              >
                Si necesitas asesoría adicional <br />
                escribenos a servicioalcliente@welli.com.co ó al Whatsapp +57
                310-5240287.
              </Typography>
            </>
          }
          confirm={notValidatedApplication}
        ></GenericModal>

        <GenericModal
          show={showRejectedModal}
          title="  No pudimos aprobar tu crédito."
          description=""
          confirm={notValidatedApplication}
          bodyComponent={<BodyRejectedComponent/>}
        ></GenericModal>

        <GlobalStyles
          styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
        />
        <CssBaseline />
        {estado === "approved" ? (
          <>
            <Container
              disableGutters
              maxWidth="sm"
              component="main"
              sx={{ pt: 4, pb: 2 }}
            >
              <Typography
                component="h1"
                variant="h4"
                align="center"
                color="text.primary"
                gutterBottom
              >
                ¡Felicitaciones!
              </Typography>
              <Typography
                variant="body2"
                align="center"
                color="primary"
                component="p"
              >
                Recibiste una aprobación de crédito por un monto máximo de:
              </Typography>
              <Typography
                component="h1"
                variant="h4"
                align="center"
                color="text.primary"
                gutterBottom
              >
                {currencyFormat(montoInicial)}
              </Typography>
            </Container>
            <Container>
              <Typography
                variant="body2"
                align="center"
                color="primary"
                component="p"
              >
                Debes ingresar el MONTO que deseas tomar:
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit(() => updateNewPaymentPlan(true))}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="monto_inicial"
                      control={control}
                      defaultValue={montoInicial}
                      fullWidth
                      rules={{
                        required: true,
                        min: MIN_AMOUNT_VALUE,
                        max: montoInicial,
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Cuánto dinero necesitas?"
                          variant="standard"
                          fullWidth
                          value={montoInicial}
                          InputProps={{
                            inputComponent: NumericFormatCustom,
                          }}
                          onChange={(e) =>
                            setValue("monto_inicial", e.target.value)
                          }
                          error={Boolean(errors.monto_inicial)}
                        />
                      )}
                      error={Boolean(errors.monto_inicial)}
                      helperText={errors.monto_inicial?.message}
                    />
                    {errors.monto_inicial && (
                      <Typography variant="caption" color="error">
                        {errors.monto_inicial.type === "required" &&
                          "El monto a solicitar es requerido"}
                        {errors.monto_inicial.type === "max" &&
                          `El monto a solicitar no puede superar los ${currencyFormat(
                            montoInicial
                          )} `}
                        {errors.monto_inicial.type === "min" &&
                          `El monto a solicitar no puede ser inferior a los a $ ${currencyFormat(
                            MIN_AMOUNT_VALUE
                          )} `}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      sx={{ mt: 1, ml: 1 }}
                    >
                      Actualizar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Container>
            {fetchingOptions == "pending" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginY: "70px",
                }}
              >
                <Typography
                  variant="h6"
                  color="primary"
                  sx={{ marginBottom: "20px" }}
                >
                  Estamos recalculando las cuotas..
                </Typography>
                <CircularProgress />
              </Box>
            )}
            {fetchingOptions == "succeded" && (
              <>
                <Container
                  disableGutters
                  maxWidth="sm"
                  component="main"
                  sx={{ pt: 2, pb: 1 }}
                >
                  <Typography
                    variant="body2"
                    align="center"
                    color="primary"
                    component="p"
                  >
                    Selecciona tu plan cuotas FIJAS :
                  </Typography>
                </Container>

                <Container maxWidth="md" component="main">
                  <Grid container spacing={5} alignItems="center">
                    <Grid item md={12} xs={12}>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={cuota}
                          onChange={(e) => setCuota(e.target.value)}
                        >
                          {cuotas.map((cuota) => (
                            <FormControlLabel
                              value={cuota.key}
                              control={<Radio />}
                              label={`${cuota.key} cuotas: $${currencyFormat(
                                cuota.value,false
                              )}`}
                              key={cuota.key}
                              sx={{ wordSpacing: "8px" }}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Container>
              </>
            )}
            {fetchingOptions == "succeded" && cuota != 0 && (
              <>
                <Typography
                  variant="body2"
                  align="center"
                  color="primary"
                  component="p"
                  sx={{ marginTop: "50px", marginBottom: "5px" }}
                >
                  Recuerda que siempre puedes prepagar tu crédito en menos
                  cuotas sin penalidad alguna
                </Typography>

                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    onClick={() => updateNewPaymentPlan(false)}
                    variant="contained"
                    sx={{ mt: 3, ml: 1 }}
                  >
                    Siguiente
                  </Button>
                </Box>
              </>
            )}
            {fetchingOptions == "succeded" && cuotas.length == 0 && (
              <Typography
                variant="body1"
                align="center"
                color="primary"
                component="p"
              >
                No se encontraron cuotas disponibles
              </Typography>
            )}
          </>
        ) : (
          <Typography
            variant="body1"
            align="center"
            color="primary"
            component="p"
          >
            Estamos verificando el estado de tu solicitud...
          </Typography>
        )}
      </React.Fragment>
    </>
  );
}
